/* JsonViewer.module.css */
.tooltip {
  display: block; /* ensures it takes full width */
  position: relative; /* parent container for absolute positioning of tooltip */
  cursor: copy;
}

.tooltipText {
  visibility: hidden;
  position: absolute;
  left: 30%; /* aligns the tooltip directly below the text */
  top: 100%; /* positions it right below the parent element */
  width: 100%; /* optional: ensures the tooltip spans the width of the URL text */
  display: block; /* ensures it does not flow in line */
  z-index: 100; /* ensures it appears on top of other content */
}
  
  .tooltip:hover .tooltipText {
    visibility: visible;
  }

  .container {
    text-align: left;
    background-color: #282c34;
  }

  .cursor-copy {
    cursor: copy;
  }

/* JsonViewer.module.css */
.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 100; /* Higher than the content so it sticks at the top */
}

.jsonTreeScrollable {
  max-height: calc(100vh - 50px); /* Adjust 50px to the actual height of your search bar */
  background-color: #272822; /* Background color for JSON tree */
}

  